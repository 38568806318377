import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  List,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  TestIdPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-data-card.example');
const stateSnippet = require('raw-loader!../../../../data/snippets/data-card-state.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="modules/data-card"
        layout="rn"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']}>
            <Text>
              The data card accepts data visualization components as children.
            </Text>
          </PropListItem>

          <PropListItem
            isRequired
            name="headerControls"
            types={['React.ReactNode']}>
            <Text>Component to render header content.</Text>
          </PropListItem>

          <PropListItem name="headerTitle" types={['string']} isRequired>
            <Text>Text to display in the data card header.</Text>
          </PropListItem>

          <PropListItem name="headerControls" types={['ReactNode']}>
            <Text>
              An element displayed next to the title within the data card
              header.
            </Text>
          </PropListItem>

          <PropListItem name="id" types={['string']} isRequired>
            <Text>
              A unique identifier for data card elements in a card group
            </Text>
          </PropListItem>

          <PropListItem name="isEmpty" types={['boolean']}>
            <Text>Determines whether the data card has stats to show.</Text>
          </PropListItem>

          <PropListItem name="isHidden" types={['boolean']}>
            <Text>
              Determines whether the data card is hidden, even if the data card
              has stats to show.
            </Text>
          </PropListItem>

          <PropListItem name="viewMode" types={['ViewMode']}>
            <Text>Determines the initial view mode of the data card.</Text>
            <List>
              <li>
                <code>'collapsed'</code> (default)
              </li>
              <li>
                <code>'preview'</code>
              </li>
              <li>
                <code>'expanded'</code>
              </li>
              <li>
                <code>'full'</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onHeaderTitlePress" types={['() => void']}>
            <Text>Called when the data card header is pressed.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <TestIdPartial componentName="data card" />
        </PropList>
      </Section>

      <Section title="Managing State">
        <Paragraph>
          To make managing the state of the selected visualization items easier,
          we created <code>useToggleArray()</code>, which is a custom React hook
          that accepts an array of labels representing the initially selected
          items, and returns an array with two values:
        </Paragraph>
        <List className="uni-margin--one--bottom">
          <li>
            <code>selectedLabels</code>: An array of currently selected labels
            to pass through the <code>selectedLabels</code> prop.
          </li>
          <li>
            <code>handleToggleItem</code>: A callback function to pass through{' '}
            <code>onToggleItem</code> to handle toggling the selected state of
            each item.
          </li>
        </List>
        <CodeSnippet code={stateSnippet} disableCodeEditing />
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Modules / Data Card"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
