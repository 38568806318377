import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const DataCard = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.DataCard;
    return <Component {...props} />;
  },
});

const Histogram = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Histogram;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/data-card.example');
const stateSnippet = require('raw-loader!../../../../data/snippets/data-card-state.example');
const snippetItems = [
  {
    label: '1',
    count: 12,
    description: 'Lorem',
  },
  {
    label: '2',
    count: 7,
    description: 'ipsum',
  },
  {
    label: '3',
    count: 8,
    description: 'dolor',
  },
  {
    label: '4',
    count: 10,
    description: 'sit',
  },
];

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{
          React: React,
          DataCard: DataCard,
          Histogram: Histogram,
          items: snippetItems,
        }}
        code={snippet}
        platform="react"
        gitHubLink="modules/data-card"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="children" types={['ReactNode']}>
            <Text>
              The data card accepts data visualization components as children.
            </Text>
          </PropListItem>

          <ClassnamePartial componentName="data card" />

          <EnvironmentPartial />

          <PropListItem name="headerControls" types={['ReactNode']}>
            <Text>
              React node displayed next to the title within the data card
              header.
            </Text>
          </PropListItem>

          <PropListItem name="headerTitle" types={['ReactNode']} isRequired>
            <Text>React node displayed in the data card header.</Text>
          </PropListItem>

          <PropListItem name="isEmpty" types={['boolean']}>
            <Text>Determines whether the data card has stats to show.</Text>
          </PropListItem>

          <PropListItem name="isHidden" types={['boolean']}>
            <Text>
              Determines whether the data card is hidden, even if the data card
              has stats to show.
            </Text>
          </PropListItem>

          <PropListItem name="viewMode" types={['ViewMode']}>
            <Text>Determines the initial view mode of the data card.</Text>
            <List>
              <li>
                <code>'collapsed'</code> (default)
              </li>
              <li>
                <code>'preview'</code>
              </li>
              <li>
                <code>'expanded'</code>
              </li>
              <li>
                <code>'full'</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onHeaderTitlePress" types={['() => void']}>
            <Text>Called when the data card header is clicked.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="data card" />
        </PropList>
      </Section>

      <Section title="Managing State">
        <Paragraph>
          To make managing the state of the selected visualization items easier,
          we created <code>useToggleArray()</code>, which is a custom React hook
          that accepts an array of labels representing the initially selected
          items, and returns an array with two values:
        </Paragraph>
        <List className="uni-margin--one--bottom">
          <li>
            <code>selectedLabels</code>: An array of currently selected labels
            to pass through the <code>selectedLabels</code> prop.
          </li>
          <li>
            <code>handleToggleItem</code>: A callback function to pass through{' '}
            <code>onToggleItem</code> to handle toggling the selected state of
            each item.
          </li>
        </List>
        <CodeSnippet
          code={stateSnippet}
          disableCodeEditing
          id="datacard-state-snippet"
        />
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Modules / Data Card"
      />
    </PlatformTab>
  );
};

export default WebTab;
